import React, { useState } from "react";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Stack from "@mui/material/Stack";
import DownloadDicomDir from "./DownloadDicomDir";
import DownloadStudy from "./DownloadStudy";
import DownloadPreview from "./DownloadPreview";
import { useMediaQuery } from "@mui/material";

const DownloadDialogButton = (props) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <Button
        sx={{
          background: "#80848c",
          fontSize: "11px",
          ":hover": {
            bgcolor: "#0290A2",
            color: "black",
          },
        }}
        onClick={handleClickOpen}
        variant="contained"
        endIcon={<FileDownloadIcon />}
      >
        Download
      </Button>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Download options</DialogTitle>
        <Divider />

        <DialogContent>
          <Box m={3}>
            {isMobile ? (
              <Stack spacing={1.5}>
                <DownloadStudy id={props.id} token={props.token} />
                <DownloadDicomDir id={props.id} token={props.token} />
                <DownloadPreview
                  id={props.id}
                  token={props.token}
                  type={"jpeg"}
                />
                <DownloadPreview
                  id={props.id}
                  token={props.token}
                  type={"png"}
                />
              </Stack>
            ) : (
              <Stack direction="row" spacing={1.5}>
                <DownloadStudy id={props.id} token={props.token} />
                <DownloadDicomDir id={props.id} token={props.token} />
                <DownloadPreview
                  id={props.id}
                  token={props.token}
                  type={"jpeg"}
                />
                <DownloadPreview
                  id={props.id}
                  token={props.token}
                  type={"png"}
                />
              </Stack>
            )}
          </Box>
          <Grid style={{ height: "100%" }} item xs={12}>
            <Collapse in={openError}>
              <Fade
                in={openError}
                timeout={{ enter: 1000, exit: 1000 }}
                addEndListener={() => {
                  setTimeout(() => {
                    setOpenError(false);
                    setOpen(false);
                  }, 2000);
                }}
              >
                <Alert sx={{ mb: 2 }} variant="filled" severity="info">
                  {errorText}
                </Alert>
              </Fade>
            </Collapse>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default DownloadDialogButton;
